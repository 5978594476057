import { useState } from "react";

const useLocalStorage = (name, defaultValue) => {
  const name_env = `${name}_${process.env.NODE_ENV || "local"}`;
  const [localSave, _setLocalSave] = useState(
    JSON.parse(localStorage.getItem(name_env)) || defaultValue
  );

  const setLocalSave = value => {
    value = value || defaultValue;
    localStorage.setItem(name_env, JSON.stringify(value));
    _setLocalSave(value);
  };

  return [localSave, setLocalSave];
};

export default useLocalStorage;
