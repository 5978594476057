import { isMinVersion } from "utils/appVersion";
import { getCookie } from "utils/common";
import { checkMobilePlatform } from "utils/user";
import useLocalStorage from "./useLocalStorage";

const useUser = (isUselocalStorage = true) => {
  const [accessToken, setAccessToken] = isUselocalStorage
    ? useLocalStorage("accessToken", "")
    : ["", () => {}];
  const babitalkApplication = window?.babitalkApplication ?? {};
  const token = babitalkApplication?.accessToken || getCookie("token") || accessToken;
  const appVersion = babitalkApplication?.appVersion || getCookie("appVersion") || "6.6.1";
  const userId = babitalkApplication?.userId || getCookie("userId") || null;
  const isMinV6 = !isMinVersion(appVersion, "6.0.0");

  if (token !== accessToken) {
    setAccessToken(token);
  }

  return {
    headers: {
      Authorization: token,
      "x-babi-version": `${checkMobilePlatform() === "ios" ? "ios" : "android"}/${appVersion}`,
      "client-id": "1207945215906956",
      "client-secret": "3b09f3e293ed187a7fa37e106dc86d78",
    },
    token,
    appVersion,
    userId,
    isMinV6,
    v6SchemeDetailPrefix: isMinV6 ? "" : "v6/detail/",
  };
};

export default useUser;
