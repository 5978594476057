import { createInstance, HackleProvider } from "@hackler/react-sdk";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import useUser from "hooks/useUser";
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store";

if (window.location.hostname !== "localhost" && process.env.REACT_APP_SENTRY_DSN) {
  const { appVersion, userId } = useUser(false);

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      "Non-Error exception captured with keys: config, data, headers, request, status",
    ],
  });

  Sentry.setContext("info", {
    appVersion,
    userId,
  });
}

const hackleClient = createInstance(process.env.REACT_APP_HACKLE_SDK_KEY, {
  debug: false,
  auto_track_page_view: false,
});

const Content = () => (
  <Provider store={store()}>
    <HackleProvider hackleClient={hackleClient}>
      <App />
    </HackleProvider>
  </Provider>
);

const rootNode = document.getElementById("root");
ReactDOM.createRoot(rootNode).render(
  window.location.hostname === "localhost" ? (
    <Content />
  ) : (
    <React.StrictMode>
      <Content />
    </React.StrictMode>
  )
);
