import Loading from "component/common/loading/LoadingContent";
import Provider from "Provider";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { appClose } from "utils/appEvent";
import { retryLazy } from "utils/retryLazy";

const Toast = retryLazy(() => import("component/common/toast"));
const Modal = retryLazy(() => import("component/common/modal"));
const BottomSheet = retryLazy(() => import("component/common/bottomSheet"));

const Homepage = retryLazy(() => import("./pages/web/Homepage"));
const Report = retryLazy(() => import("./pages/web/Report"));

// mobile
const Notice = retryLazy(() => import("./pages/mobile/Notice"));
const Prepayment = retryLazy(() => import("./pages/mobile/Prepayment"));
const Policy = retryLazy(() => import("./pages/mobile/Policy"));
const SideEffectReview = retryLazy(() => import("./pages/mobile/SideEffectReview"));

// offlineSignup
const offlineSignup = retryLazy(() => import("./pages/offlineSignup"));

const Event = retryLazy(() => import("./pages/mobile/Event"));

const RouteList = retryLazy(() => import("./pages/RouteList"));

const reload = () => window.location.reload();

function App() {
  // 웹뷰로 사용하는 페이지에서 필요한 함수 설정
  useEffect(() => {
    const eventFromApp = (eventName, param) => {
      const event = new CustomEvent(eventName, { detail: param });
      window.dispatchEvent(event);
    };

    const goBack = () => {
      const prevPage = window.location.href;
      window.history.back();

      setTimeout(function () {
        if (window.location.href === prevPage) {
          appClose();
        }
      }, 100);
    };

    window.eventFromApp = eventFromApp;
    window.goBack = goBack;
    window.modifyContent = () => {};
    window.handleClientBackToWebview = () => {};
  }, []);

  const modal_reducer = useSelector(store => store.modal_reducer);

  return (
    <Provider>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            {/* 홈페이지 */}
            <Route path="/report" component={Report} />

            {/* 웹뷰용 페이지 */}
            <Route path="/notice" component={Notice} />
            <Route path="/(prepayment|onsite_payment)" component={Prepayment} />
            <Route path="/policy" component={Policy} />
            <Route path="/side_effect_review" component={SideEffectReview} />

            {/* 단기 이벤트 페이지 */}
            <Route path="/event" component={Event} />

            {/* 기타 페이지 */}
            <Route path="/route_list" component={RouteList} />
            <Route path="/post" onEnter={reload} />

            {/* 현장 결제 페이지 */}
            <Route path="/offline_signup" component={offlineSignup} />

            {/* 상단에 정의되지 않은 경로는 홈페이지로 랜딩 */}
            <Route path="*" component={Homepage} />
          </Switch>
        </Suspense>
      </Router>

      {/* 팝업 */}
      <Suspense fallback={null}>
        {modal_reducer.show && <Modal {...modal_reducer} />}
        <BottomSheet />
        <Toast />
      </Suspense>
    </Provider>
  );
}

export default App;
