import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useUser from "hooks/useUser";
import { createContext } from "react";

export const UserInfoContext = createContext();

const Provider = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserInfoContext.Provider value={useUser()}>{children}</UserInfoContext.Provider>
      </QueryClientProvider>
    </>
  );
};

export default Provider;
